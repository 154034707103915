import React from 'react'
import { graphql } from 'gatsby'

import Layout from 'layouts/Base'
import SEO from 'components/shared/SEO'
import RoomPage from 'components/RoomPage'
import { formatNodes } from 'utils/formatNodes'

function BedroomPage({data}) {
  const recommended = formatNodes(data.recommendedQuery)
  const bookshelData = formatNodes(data.bookshelfQuery)
  const bedSideData = formatNodes(data.bedsideQuery)
  const windowData = formatNodes(data.windowQuery)
  const pageData = {
    header: {
      title: "Plants for the Bedroom",
      copy: "Bring soothing plants back to your place of rest.",
      image: data.bed.childImageSharp.fluid
    },
    recommended: {
      title: "Top Picks",
      cta: {
        title: "View all bedroom plants",
        link: "/plants?bedroom"
      },
      items: recommended
    },
    sections: [
      {
        title:"Plants for the bedside",
        image: data.bedSide.childImageSharp.fluid,
        items: bedSideData
      },
     
      {
        title: "Plants for the bookshelf",
        image: data.wallPlant.childImageSharp.fluid,
        items: bookshelData
      },
      {
        title: "Plants for the window",
        image: data.windowPicture.childImageSharp.fluid,
        items: windowData
      }
    ]
  }
	return <RoomPage data={pageData}/>
}

export default ({ data, location }) => {
  return (
    <>
      <SEO title="Plants for the bedroom"/>
      <BedroomPage data={data} />
    </>
  )
}

export const query = graphql`
  query BedroomQuery {
    bed: file(relativePath: { eq: "bedroom/bed.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    nightStand: file(relativePath: { eq: "bedroom/nightstand.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    windowPicture: file(relativePath: { eq: "bedroom/window.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    wallPlant: file(relativePath: { eq: "bedroom/wall-plant.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bedSide: file(relativePath: { eq: "bedroom/bedside.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    recommendedQuery: allStripeSku(
      limit: 12, 
      filter: { metadata: { bedroom: { in: "True" } } },
      sort: { fields: [metadata___ranking], order: ASC  }
    ) {
      group(field: product___id) {
        edges {
          node {
            ...Sku
          }
        }
      }
    }
    bedsideQuery: allStripeSku(
      filter: { metadata: { b_bedside: { in: "True" } } },
      sort: { fields: [metadata___ranking], order: ASC  }
    ) {
      group(field: product___id) {
        edges {
          node {
            ...Sku
          }
        }
      }
    }
    bookshelfQuery: allStripeSku(
      limit: 4,
      filter: { metadata: { b_bookshelf: { in: "True" } } },
      sort: { fields: [metadata___ranking], order: ASC  }
      ) {
      group(field: product___id) {
        edges {
          node {
            ...Sku
          }
        }
      }
    }
    windowQuery: allStripeSku(
      limit:4,
      filter: { metadata: { b_window: { in: "True" } } },
      sort: { fields: [metadata___ranking], order: ASC  }
    ) {
      group(field: product___id) {
        edges {
          node {
            ...Sku
          }
        }
      }
    }
  }
`